<template>
    <div class="relative">
        <picture v-if="!props.src.endsWith('.svg')">
            <source
                :srcset="srcset"
                :sizes="props.sizes"
                :media="`(min-width: ${minWidth}px)`"
            />
            <NuxtImg
                :src="optimizedImageUrl({ width: defaultWidth })"
                :fetchpriority="preload ? 'high' : undefined"
                :loading="preload ? 'eager' : 'lazy'"
                :decoding="preload ? 'sync' : 'async'"
                provider="storyblok"
                format="webp"
                fit="in"
                :alt="props.alt"
                class="h-full w-full"
                :class="hasObjectCoverClass ? 'object-cover' : 'object-contain'"
                :width="imageWidth"
                :height="imageHeight"
                @load="setLoaded"
                @error="setLoaded"
            />
            <transition name="fade">
                <div
                    v-show="!imageLoaded && !preload"
                    class="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-white transition-all"
                >
                    <Spinner class="text-3xl"></Spinner>
                </div>
            </transition>
        </picture>
        <img
            v-else
            :src="src"
            :alt="alt"
            class="h-full w-full object-contain"
        />
    </div>
</template>

<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { computed } from 'vue';

const props = withDefaults(
    defineProps<{
        src: string;
        alt?: string | undefined;
        sizes?: string;
        quality?: number;
        preload?: boolean;
        imageWidth?: number | undefined;
        imageHeight?: number | undefined;
    }>(),
    {
        alt: '',
        sizes: '120px',
        quality: 90,
        preload: true,
        imageWidth: undefined,
        imageHeight: undefined,
    },
);

const attrs = useAttrs();

const hasObjectCoverClass = computed(() => {
    return attrs.class?.split(' ').includes('object-cover') ?? false;
});

const imageLoaded = ref(false);

const parsedWidths = computed(() => {
    if (!props.sizes) return [];
    const regex = /\d+(?=px)/g;
    return props.sizes.match(regex)?.map(Number) || [];
});

const defaultWidth = computed(() => {
    return Math.max(...parsedWidths.value);
});

const minWidth = computed(() => {
    return Math.min(...parsedWidths.value);
});

const optimizedImageUrl = ({
    width,
    height = 0,
    format = 'webp',
}: {
    width: number;
    height?: number;
    format?: string;
}) => {
    return `${props.src}/m/${width}x${height}/filters:format(${format}):quality(${props.quality})`;
};

const srcset = computed(() => {
    return parsedWidths.value
        .map((width) => `${optimizedImageUrl({ width })} ${width}w`)
        .join(', ');
});

if (props.preload) {
    useHead({
        link: [
            {
                rel: 'preload',
                as: 'image',
                href: optimizedImageUrl({ width: defaultWidth.value }),
            },
        ],
    });
}

const setLoaded = () => {
    imageLoaded.value = true;
};

onMounted(() => {
    const img = new Image();
    img.src = optimizedImageUrl({ width: defaultWidth.value });

    if (img.complete) {
        setLoaded();
    } else {
        img.onload = setLoaded;
        img.onerror = setLoaded;
    }

    setTimeout(() => {
        if (!imageLoaded.value) {
            setLoaded();
        }
    }, 5000);
});
</script>
